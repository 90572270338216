import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { calculateOrderVatBreakdown, slugify } from "@repo/system";
import { T, useT } from "@repo/transifex";
import { useFieldArray, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { Button, Dialog } from "~/components";
import { useCurrentTill, useProducts } from "~/hooks/queries";
import { basketActions, useBasket } from "~/providers/store/basket";
import { useEmployee } from "~/providers/store/employee";

import { CustomProduct } from "./CustomProduct";

export type CustomProductForm = {
  customProduct: {
    amount: number;
    name: string;
    category:
      | {
          value: string;
          label: string;
        }
      | undefined;
    price: number | undefined;
    vatRate: number;
  }[];
};

const CustomProductHeader = () => (
  <div className="relative mb-8 flex w-full items-center justify-center border-b border-divider-main py-2 pb-5">
    <Link className="absolute left-0" to="..">
      <Button className="text-danger-dark" variant="transparent">
        <T _str="Cancel" />
      </Button>
    </Link>
    <p className="font-bold text-text-primary">
      <T _str="Create custom order" />
    </p>
  </div>
);

export const CustomProducts = () => {
  const { data: till } = useCurrentTill();
  const t = useT();
  const navigate = useNavigate();

  const { groupName } = useEmployee();
  const { diningOption } = useBasket();
  const { data: productsData } = useProducts({
    groupName,
  });
  const taxRules = productsData?.taxRules ?? [];

  // Get the tax rules for the selected dining option
  const taxOverwrites = taxRules.filter((taxRule) => taxRule.directives === null || taxRule.directives.conditions?.[0]?.condition.inputId === diningOption?.inputId)
  // Get the tax rate for the selected dining option
  const selectedDiningOptionVatRate = taxOverwrites?.find((taxRule) => taxRule.directives?.conditions?.[0]?.condition.option === diningOption?.value || (taxRule.directives === null && (taxRule.products === "all" || taxRule.products === "all-default")))?.vatRate;

  const vatRate = selectedDiningOptionVatRate ?? till?.moduleConfig.vatRate ?? 0.25;

  const DEFAULT_CUSTOM_PRODUCT = {
    amount: 1,
    name: "",
    category: {
      value: t("Custom product"),
      label: t("Custom product"),
    },
    price: undefined,
    vatRate: vatRate * 100,
  };

  const { control, watch, handleSubmit } = useForm<CustomProductForm>({
    defaultValues: {
      customProduct: [DEFAULT_CUSTOM_PRODUCT],
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "customProduct",
  });

  return (
    <Dialog backUrl=".." className="p-5" size="lg">
      <CustomProductHeader />

      <form
        className="flex flex-col gap-8"
        onSubmit={handleSubmit((data) => {
          const customProducts = data.customProduct.map((product) => {
            const rawUnitPrice = product.price ?? 0;
            const name = product.name || t("Diverse");
            const category = product.category?.value || t("Custom product");
            const id = `custom-${slugify(name)}-${slugify(
              category
            )}-${rawUnitPrice}-${uuidv4()}`;
            const amount = product.amount ?? 1;

            const customProduct = {
              amount: 1,
              unitPrice: rawUnitPrice,
              vatRate: (product.vatRate ?? vatRate) / 100,
              options: {},
              productId: id,
            };

            const unitPrice = calculateOrderVatBreakdown([
              customProduct,
            ]).withVat;

            return {
              count: amount,
              name,
              category,
              rawUnitPrice,
              unitPrice,
              vatRate: (product.vatRate ?? vatRate) / 100,
              options: {},
              productId: id,
            };
          });

          basketActions.addCustomProducts(customProducts);
          navigate("..");
        })}
      >
        {fields.map(({ id }, index) => (
          <CustomProduct
            control={control}
            index={index}
            key={id}
            remove={remove}
            watch={watch}
          />
        ))}

        <Button
          className="w-full"
          icon={faPlus}
          onClick={() => {
            append(DEFAULT_CUSTOM_PRODUCT);
          }}
          variant="secondary"
        >
          <T _str="Add product" />
        </Button>

        <Button className="w-full" size="large" type="submit">
          <T _str="Add to basket" />
        </Button>
      </form>
    </Dialog>
  );
};
