import { T, useT } from "@repo/transifex";
import { Link, Navigate, Outlet } from "react-router-dom";

import { Button, DialogError, DialogLoading, LayoutLogo } from "~/components";
import { useCurrentTill } from "~/hooks/queries";
import {
  useAppDispatch,
  useDeviceData,
  usePrinterError,
} from "~/providers/app";
import { useAuth } from "~/providers/store/auth";

const AppLayout = () => {
  const dispatch = useAppDispatch();
  const t = useT();
  const { deviceId, tillId } = useDeviceData();

  const printerError = usePrinterError();

  const { data: till, status } = useCurrentTill();

  const auth = useAuth();

  if (tillId && status === "pending")
    return (
      <LayoutLogo>
        <DialogLoading>
          <p className="text-center text-xl font-semibold">
            <T _str="Connecting device" />
          </p>
        </DialogLoading>
      </LayoutLogo>
    );

  if (printerError)
    return (
      <LayoutLogo>
        <DialogError error={printerError}>
          <Button
            onClick={() => {
              dispatch({ type: "CLEAR_PRINTER_ERROR" });
            }}
            variant="danger"
          >
            <T _str="Okay" />
          </Button>
        </DialogError>
      </LayoutLogo>
    );

  if (
    status === "error" ||
    (!auth.isKanplaAdmin && till?.deviceId !== deviceId)
  )
    return (
      <LayoutLogo>
        <DialogError error={t("Device doesn't have a license")}>
          <Link to="/license">
            <Button variant="danger">
              <T _str="Register Device" />
            </Button>
          </Link>
        </DialogError>
      </LayoutLogo>
    );

  if (auth.status === "till")
    return (
      <LayoutLogo>
        <DialogError error={t("You need a profile")}>
          <Link to="/profiles">
            <Button variant="danger">
              <T _str="Choose a profile" />
            </Button>
          </Link>
        </DialogError>
      </LayoutLogo>
    );

  if (!auth.isOnline && till?.type === "self-service") {
    return <Navigate replace to="/offline" />;
  }

  return <Outlet />;
};

export { AppLayout };
