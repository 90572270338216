import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faCoins, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { T } from "@repo/transifex";
import type { PaymentProvider } from "@repo/types";
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { Button, DialogError } from "~/components";
import { MobilePayIcon } from "~/components/MobilePayIcon";
import { useCurrentTill } from "~/hooks/queries";
import {
  useAppContext,
  useAppDispatch,
  useBasketProducts,
  useSplitPaymentFlow,
} from "~/providers/app";
import { useEmployee } from "~/providers/store/employee";

import { isPaymentButtonHidden } from "./isPaymentButtonHidden";

type PaymentButtonsProps = {
  isSubmitDisabled: boolean;
  onCreate: (paymentProvider: PaymentProvider) => void;
  // Difference to be paid, if the user saldo does not cover the cost
  restPrice?: string | null;
};

export const PaymentButtons = ({
  isSubmitDisabled,
  onCreate,
  restPrice,
}: PaymentButtonsProps) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { saldo: unitSaldo } = useEmployee();
  const {
    hardware: { printer },
  } = useAppContext();
  const dispatch = useAppDispatch();

  const [terminalModalOpen, setTerminalModalOpen] = useState(false);

  const { data: till, status: tillStatus } = useCurrentTill();

  const { totalUnit, isEmpty } = useBasketProducts();

  const { isActive: isSplitPaymentActive } = useSplitPaymentFlow();

  if (tillStatus !== "success") return null;

  const { paymentMethod: modulePaymentMethod, hasHybridBilling } =
    till.moduleConfig;

  const hiddenPayments = isPaymentButtonHidden({
    till,
    printer,
    modulePaymentMethod,
    hasHybridBilling,
    totalUnit,
    unitSaldo,
    restPrice,
    isBasketEmpty: isEmpty,
    isSplitPaymentActive,
  });

  const allPaymentsAreDisabled =
    Object.values(hiddenPayments).every((isHidden) => isHidden) &&
    !till.terminalId;

  const handleOnClick = (paymentProvider: PaymentProvider) => {
    dispatch({ type: "COUNTDOWN_STOP" });
    dispatch({ type: "COUNTDOWN_RESET" });
    onCreate(paymentProvider === "card-v3" ? "card" : paymentProvider);
    navigate({
      pathname: `${pathname}/${paymentProvider === "mobilepay_own" ? "mobilepay" : paymentProvider
        }`,
      search
    }
    );
  };

  return (
    <>
      <div className="flex flex-col">
        <Button
          className="flex-1 rounded-none"
          disabled={isSubmitDisabled}
          hidden={hiddenPayments.billing}
          onClick={() => {
            handleOnClick("billing");
          }}
          size="large"
          variant="primary"
        >
          <T _str="Complete purchases" />
        </Button>

        <Button
          className="flex-1 rounded-none"
          disabled={isSubmitDisabled}
          hidden={hiddenPayments.credit}
          onClick={() => {
            handleOnClick("credit");
          }}
          size="large"
          variant="primary"
        >
          <T _str="Complete purchases" />
        </Button>

        <div className="flex w-full">
          <Button
            className="flex-1 rounded-none"
            disabled={isSubmitDisabled}
            hidden={hiddenPayments.mobilepay_own}
            icon={<MobilePayIcon />}
            onClick={() => {
              handleOnClick("mobilepay_own");
            }}
            size="large"
            variant="info-inverted"
          >
            <T _str="MobilePay" />
          </Button>

          <Button
            className="flex-1 rounded-none"
            disabled={isSubmitDisabled}
            hidden={hiddenPayments.cash}
            icon={faCoins}
            onClick={() => {
              handleOnClick("cash");
            }}
            size="large"
            variant="secondary"
          >
            <T _str="Cash" />
          </Button>

          <Button
            className="flex-1 rounded-none"
            disabled={isSubmitDisabled}
            hidden={hiddenPayments.zero_payment}
            onClick={() => {
              handleOnClick("cash");
            }}
            size="large"
            variant="primary"
          >
            <T _str="Complete order" />
          </Button>
        </div>

        {till.terminalId ? (
          <Button
            className="flex-1 rounded-none"
            disabled={isSubmitDisabled}
            hidden={hiddenPayments.card}
            icon={faCcVisa}
            onClick={() => {
              if (till.config.isAdyenV3Enabled) {
                handleOnClick("card-v3");
              } else {
                handleOnClick("card");
              }
            }}
            size="large"
          >
            {restPrice && modulePaymentMethod === "credit" ? (
              <T _str="Pay {price} with card" price={restPrice} />
            ) : (
              <T _str="Card" />
            )}
          </Button>
        ) : (
          <Button
            className="flex-1 rounded-none"
            disabled={isSubmitDisabled}
            hidden={hiddenPayments.card}
            icon={faWarning}
            onClick={() => {
              setTerminalModalOpen(true);
            }}
            size="large"
            variant="danger-light"
          >
            <T _str="You need to connect a terminal" />
          </Button>
        )}
      </div>

      {/* Terminal not connected  */}
      <DialogError
        childrenPosition="top"
        onOutsideClick={() => {
          setTerminalModalOpen(false);
        }}
        open={terminalModalOpen}
        size="md"
      >
        <div className="flex flex-col text-center">
          <p className="mb-3 text-2xl">
            <T _str="You need to connect a terminal" />
          </p>
          <p>
            <T
              _str="Go to {deviceSettings} and connect a terminal"
              deviceSettings={
                <NavLink
                  className="font-semibold underline hover:text-primary-dark"
                  to="/settings/devices"
                >
                  <T _str="Device Settings" />
                </NavLink>
              }
            />
          </p>
        </div>
      </DialogError>

      {/* No payment method available */}
      <DialogError
        childrenPosition="top"
        open={allPaymentsAreDisabled}
        size="md"
      >
        <div className="flex flex-col text-center">
          <p className="mb-3 text-2xl">
            <T _str="No payment method has been activated" />
          </p>
          <p>
            <T
              _str="You can connect a cashdrawer and terminal under {deviceSettings}"
              deviceSettings={
                <NavLink
                  className="font-semibold underline hover:text-primary-dark"
                  to="/settings/devices"
                >
                  <T _str="Device Settings" />
                </NavLink>
              }
            />
          </p>
        </div>
      </DialogError>
    </>
  );
};
