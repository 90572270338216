import { createQueryKeys } from "@lukemorales/query-key-factory";
import type { apiContract } from "@kanpla/api-contract";
import { useQuery } from "@tanstack/react-query";
import type { ClientInferRequest } from "@ts-rest/core";

import { getAuth, useAuth } from "~/providers/store/auth";
import { useEmployee } from "~/providers/store/employee";

import { client, getAuthHeader } from "./api.client";

type GetRequest = ClientInferRequest<typeof apiContract.v1.pos.products.get>;

export const productsKeys = createQueryKeys("products", {
  list: ({
    groupName,
    childId,
  }: {
    groupName: string | null | undefined;
    childId: string | null;
  }) => ({
    queryKey: [{ groupName, childId }],
  }),
});

function useProducts({
  groupName,
}: Pick<GetRequest["query"], "language"> & {
  groupName?: string | null;
}) {
  const { employeeChildId } = useEmployee();

  const { childId: profileChildId } = useAuth();

  const childId = employeeChildId ?? profileChildId;

  return useQuery({
    ...productsKeys.list({
      childId,
      groupName,
    }),
    queryFn: async () => {
      const auth = getAuth();

      if (auth.status !== "profile") {
        throw new Error("Profile not selected");
      }

      const { moduleId, schoolId, language, tillId, locale } = auth;

      const res = await client.v1.pos.products.get({
        query: {
          tillId,
          moduleId,
          schoolId,
          language,
          priceLocale: locale,
          childId: childId ?? undefined,
          groupName: groupName ?? undefined,
        },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
  });
}

export { useProducts };
