import { faQrcode, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { priceFormatter } from "@repo/system";
import { T } from "@repo/transifex";
import { clsx } from "clsx";
import { useState, type ReactNode } from "react";

import { Button, DialogCancelSplitPayment } from "~/components";
import { useCurrentTill } from "~/hooks/queries";
import { useCurrentSchool } from "~/hooks/queries/schools";
import {
  useAppDispatch,
  useBasketProducts,
  useSplitPaymentFlow,
} from "~/providers/app";
import { useAuth } from "~/providers/store/auth";
import { basketActions } from "~/providers/store/basket";
import { employeeActions, useEmployee } from "~/providers/store/employee";

export const BasketHeader = () => {
  const { groupName, saldo, displayName } = useEmployee();

  const {
    meta: { currency },
    totalUnit,
    isEmpty,
  } = useBasketProducts();

  const authData = useAuth();

  const { data: till } = useCurrentTill();
  const { data: school } = useCurrentSchool();

  if (!till || till.type === "attended") {
    return <BasketHeaderWrapper isEmpty={isEmpty} />;
  }

  // if no options are found don't show the header
  const options = school?.selector?.options || [];
  if (options.length <= 1 && !till.config.hasEmployeeCardReader) {
    return <BasketHeaderWrapper isEmpty={isEmpty} />;
  }

  // if till type is self-service and employee card reader is disabled
  if (
    till.type === "self-service" &&
    till.config.selectorSetup === "show-selectors" &&
    !till.config.hasEmployeeCardReader
  ) {
    return (
      <BasketHeaderWrapper isEmpty={isEmpty}>
        <div>
          {/* there can be a selected group name or the user continued as a guest */}
          <p>{groupName ?? "Guest"}</p>
        </div>
      </BasketHeaderWrapper>
    );
  }

  // till type is self-service, employee card reader is enabled and saldo is a number
  if (
    till.type === "self-service" &&
    till.config.hasEmployeeCardReader &&
    typeof saldo === "number"
  ) {
    const unitSaldo = calculateSaldo(totalUnit, saldo);

    return (
      <BasketHeaderWrapper isEmpty={isEmpty}>
        <div>
          <p>{displayName}</p>
          <p className="text-sm">
            <T _str="Saldo" />{" "}
            <b className="text-lg">
              {priceFormatter({
                value: unitSaldo,
                currency,
                locale: authData.locale,
              })}
            </b>
          </p>
        </div>
      </BasketHeaderWrapper>
    );
  }

  // till type is self-service, employee card reader is enabled and saldo has never been recharged
  if (till.type === "self-service" && till.config.hasEmployeeCardReader) {
    return (
      <BasketHeaderWrapper isEmpty={isEmpty}>
        <div>
          <p className="text-sm">{groupName}</p>
          <p>{displayName}</p>
        </div>
      </BasketHeaderWrapper>
    );
  }

  return <BasketHeaderWrapper isEmpty={isEmpty} />;
};

function calculateSaldo(totalBakset: number, saldo: number) {
  const calculatedSaldo = saldo - totalBakset;

  if (calculatedSaldo < 0) return 0;

  return calculatedSaldo;
}

type BasketHeaderWrapperProps = {
  children?: ReactNode;
  isEmpty: boolean;
};

const BasketHeaderWrapper = ({
  children,
  isEmpty,
}: BasketHeaderWrapperProps) => {
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { displayName } = useEmployee();

  const { data: till } = useCurrentTill();

  const { isActive: isSplitPaymentActive } = useSplitPaymentFlow();

  return (
    <div className="flex flex-col divide-y divide-divider-main border-b border-divider-main">
      {children && till?.type === "self-service" ? (
        <div className="flex items-center justify-between gap-x-4 px-4 py-3">
          {children}

          <Button
            onClick={() => {
              basketActions.reset();
              basketActions.resetDiningOption();
              employeeActions.reset();
            }}
            variant="info-inverted"
          >
            <T _str="Change user" />
          </Button>
        </div>
      ) : null}

      <div className="flex items-center justify-between px-4 py-3">
        <span className="text-xl font-semibold text-text-secondary">
          <T _str="Basket" />
        </span>

        {displayName && till?.type === "attended" ? (
          <div className="flex items-center gap-2">
            <Button icon={faQrcode} variant="primary-light">
              {displayName}
            </Button>
            <Button
              className={clsx("border border-divider-main")}
              onClick={() => {
                employeeActions.reset();
              }}
              size="medium"
              variant="light"
            >
              <T _str="Log out" />
            </Button>
          </div>
        ) : null}

        <Button
          className={clsx("border border-divider-main", {
            invisible: isEmpty,
          })}
          icon={faTrash}
          iconClassName="text-text-secondary"
          onClick={() => {
            if (isSplitPaymentActive) {
              setCancelDialogOpen(true);
            } else {
              dispatch({ type: "COUNTDOWN_RESET" });
              basketActions.reset();
            }
          }}
          size="medium"
          variant="light"
        />
      </div>

      {cancelDialogOpen ? (
        <DialogCancelSplitPayment
          onClose={() => {
            setCancelDialogOpen(false);
          }}
        />
      ) : null}
    </div>
  );
};
