import type { AllowanceUsage, ProductBasic } from "@repo/types";

import type { BasketStore } from "~/providers/store/basket";

type CalculateAllowanceArgs = {
  allowanceUsages: AllowanceUsage[] | null;
  basket: BasketStore["products"];
  allProducts: ProductBasic[];
};

/** Function that recalculates the allowance based on the products in the basket
 * @returns {AllowanceUsage[] | null} the updated allowance
 */
export function calculateAllowance({
  allowanceUsages,
  basket,
  allProducts,
}: CalculateAllowanceArgs): AllowanceUsage[] | null {
  if (!allowanceUsages) return null;

  return allowanceUsages.map((allowance) => {
    const productIds =
      allowance.numberOfProducts?.productIds ??
      allowance.unitPrice?.productIds ??
      [];

    if (!productIds.length) return allowance;

    const quantityInBasket = Object.entries(basket).reduce(
      (acc, [productId, productInfo]) => {
        if (productIds.includes(productId)) {
          return acc + productInfo.count;
        }

        return acc;
      },
      0
    );

    if (!quantityInBasket) return allowance;

    if (allowance.unitPrice) {
      const unitPriceInBasket = Object.entries(basket).reduce(
        (acc, [productId, productInfo]) => {
          if (productIds.includes(productId)) {
            const product = allProducts.find((p) => p.id === productId);
            if (!product) return acc;

            return acc + product.unitPrice * productInfo.count;
          }

          return acc;
        },
        0
      );

      const diff =
        allowance.unitPrice.amount - unitPriceInBasket * quantityInBasket;

      return {
        ...allowance,
        isAllowanceOver: diff <= 0,
        unitPrice: {
          ...allowance.unitPrice,
          amount: diff,
        },
      };
    }

    if (allowance.numberOfProducts) {
      const diff = allowance.numberOfProducts.amount - quantityInBasket;

      return {
        ...allowance,
        isAllowanceOver: diff <= 0,
        numberOfProducts: {
          ...allowance.numberOfProducts,
          amount: diff,
        },
      };
    }

    return allowance;
  });
}
