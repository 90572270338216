import {
  getAllowanceMaxAmount,
  getAllowancesForProduct,
  priceFormatter,
  round,
} from "@repo/system";
import { useT } from "@repo/transifex";
import type {
  Currency,
  PosProduct,
  StepVariant,
  StepVariantWeight,
  VariantGroup,
} from "@repo/types";
import { useContext, useMemo, type Dispatch } from "react";
import { useSearchParams } from "react-router-dom";

import {
  useCurrentTill,
  useOrder,
  useProducts,
  useProfiles,
  useSession,
} from "~/hooks/queries";
import { useAuth } from "~/providers/store/auth";
import type { BasketStore } from "~/providers/store/basket";
import { useBasket } from "~/providers/store/basket";
import { calculateAllowance } from "~/utils/allowances";
import { calculateUnitPriceByUnitSystem } from "~/utils/calculateUnitPriceByUnitSystem";
import { calculateVariantTotal } from "~/utils/calculateVariantTotal";
import { getVatRateFromTaxRules } from "~/utils/getVatRateFromTaxRules";

import { useEmployee } from "../store/employee";

import { AppContext, AppDispatchContext } from "./app-provider";
import type { AppAction, AppState } from "./types";

/**
 * Hook to get the app state
 * @returns {AppState}
 */
export function useAppContext(): AppState {
  return useContext(AppContext);
}

/**
 * Hook to get the dispatch function
 * for sending actions to the app reducer
 * @returns {Dispatch<AppAction>}
 */
export function useAppDispatch(): Dispatch<AppAction> {
  return useContext(AppDispatchContext);
}

export function useCashDrawerStatus() {
  const { hardware } = useAppContext();

  return useMemo(() => {
    return hardware.cashDrawer.status;
  }, [hardware.cashDrawer.status]);
}

export function useDeviceData() {
  const { device } = useAppContext();

  return useMemo(() => {
    return device;
  }, [device]);
}

export function usePrinterStatus() {
  const { hardware } = useAppContext();

  return useMemo(() => {
    return hardware.printing.status;
  }, [hardware.printing.status]);
}

export function usePrinterError() {
  const t = useT();
  const { hardware } = useAppContext();

  switch (hardware.printerError) {
    case "connection_error":
      return t(
        "Printer has lost connection, please check printer and try reconnecting"
      );
    case "cutter_error":
      return t(
        "Cutter Error found on printer, please check printer and try again"
      );
    case "cover_open":
      return t("Printer cover is open, please close the cover and try again");
    case "paper_empty":
      return t("Printer paper is empty, please refill paper and try again");
    case "paper_jam_error":
      return t(
        "Paper Jam Error found on printer, please check printer and try again"
      );
    case "paper_separator_error":
      return t(
        "Paper Separator Error found on printer, please check printer and try again"
      );
    case "roll_position_error":
      return t(
        "Roll Position Error found on printer, please check printer and try again"
      );
    case "unknown_error":
      return t(
        "Unknown Error found on printer, please check printer and try again"
      );
    default:
      return null;
  }
}

export function useCountDownState() {
  const { countdown } = useAppContext();

  return useMemo(() => {
    return {
      enabled: countdown.enabled,
      count: countdown.count,
    };
  }, [countdown.count, countdown.enabled]);
}

export type PosProductInBasket = (
  | {
    options?: StepVariant[];
    type: "variant";
    unitSystem: "piece";
  }
  | {
    options?: StepVariantWeight[];
    type: "variant-weight";
    unitSystem?: "1-gram" | "100-grams" | "kilogram";
  }
  | {
    options?: never;
    type: "custom" | "regular";
    unitSystem: "piece";
  }
) &
  Pick<
    PosProduct,
    | "id"
    | "category"
    | "quantity"
    | "name"
    | "unitPrice"
    | "totalDisplayPrice"
    | "limit"
    | "productLineId"
  > & {
    totalOriginalDisplayPrice: string;
    basketId: string;
    discountUnitPrice?: number;
    discountPercentage?: number;
    totalUnitPrice: number;
    totalPrice: number;
    vatRate: number;
    exclVatUnitPrice: number;
  };

export type UseBasketProductsReturn = {
  basket: BasketStore["products"];
  variants: VariantGroup[];
  products: PosProduct[];
  productsInBasket: PosProductInBasket[];
  meta: {
    currency: Currency;
    offset: number;
  };
  isEmpty: boolean;
  totalUnit: number;
  totalUnitDiscounted: number;
  productsStatus: "loading" | "success" | "error" | "pending";
};

const defaultMeta: UseBasketProductsReturn["meta"] = {
  currency: "DKK",
  offset: 0,
};

/**
 * Hook to get the products in the basket
 * @returns {UseBasketProductsReturn}
 */
export function useBasketProducts(): UseBasketProductsReturn {
  const authData = useAuth();
  const { groupName, allowanceUsages } = useEmployee();

  const { data, status: productsStatus } = useProducts({
    groupName,
  });

  const { products: basket, orderDiscount, diningOption } = useBasket();

  return useMemo(() => {
    if (productsStatus !== "success") {
      return {
        basket,
        products: [],
        meta: defaultMeta,
        isEmpty: true,
        totalUnit: 0,
        totalUnitDiscounted: 0,
        productsStatus,
        productsInBasket: [],
        variants: [],
      };
    }

    const { products: allProducts, meta, taxRules } = data;

    const newAllowance = calculateAllowance({
      allowanceUsages,
      basket,
      allProducts,
    });

    // products extended with allowance rules based on the current basket
    const products = allProducts.map<PosProduct>((product) => {
      const basketProduct = basket.find(
        (item) => item.productId === product.id
      );

      const quantity = basketProduct?.count || 0;

      const allowances = getAllowancesForProduct({
        productId: product.id,
        allowanceUsages: newAllowance,
      });
      const limit = getAllowanceMaxAmount({
        productPrice: product.unitPrice,
        allowanceUsages: allowances.filter((a) => !a.discountId),
      });

      const { vatRate, price } = getVatRateFromTaxRules({
        product,
        taxRules,
        diningOption,
      });

      const shouldCalculatePrice =
        product.vatRate !== vatRate && price === "excl";
      const shouldCalculateExclVatUnitPrice =
        product.vatRate !== vatRate && price === "incl";

      const unitPrice = shouldCalculatePrice
        ? round(product.exclVatUnitPrice * (1 + vatRate), 2)
        : product.unitPrice;

      const exclVatUnitPrice = shouldCalculateExclVatUnitPrice
        ? product.unitPrice / (1 + vatRate)
        : product.exclVatUnitPrice;

      const displayPrice = shouldCalculatePrice
        ? priceFormatter({
          value: unitPrice,
          currency: meta.currency,
          locale: authData.locale,
        })
        : product.displayPrice;

      const prod: PosProduct = {
        ...product,
        quantity,
        totalDisplayPrice: priceFormatter({
          value: unitPrice * quantity,
          currency: meta.currency,
          locale: authData.locale,
        }),
        limit,
        category: product.category,
        vatRate,
        unitPrice,
        displayPrice,
        exclVatUnitPrice,
      };

      return prod;
    });

    // products in basket extended with display prices and total price
    const productsInBasket = basket.flatMap<PosProductInBasket>((item) => {
      if (item.count === 0) {
        return [];
      }

      if (item.type === "custom") {
        const totalPrice = item.unitPrice * item.count;
        const totalDisplayPrice = priceFormatter({
          value: totalPrice,
          currency: meta.currency,
          locale: authData.locale,
        });

        const customProduct: PosProductInBasket = {
          id: item.productId,
          name: item.name,
          quantity: item.count,
          unitPrice: item.unitPrice,
          category: item.category,
          totalDisplayPrice,
          totalOriginalDisplayPrice: totalDisplayPrice,
          limit: null,
          basketId: item.id,
          totalPrice,
          totalUnitPrice: item.unitPrice,
          type: item.type,
          productLineId: item.id,
          unitSystem: "piece",
          vatRate: item.vatRate,
          exclVatUnitPrice: item.rawUnitPrice,
        };

        return customProduct;
      }

      const product = products.find((p) => p.id === item.productId);
      if (!product) {
        return [];
      }

      let totalUnitPrice = product.unitPrice;
      let totalVariantPrice = 0;

      if (item.type === "variant") {
        totalVariantPrice = calculateVariantTotal(
          item.options,
          product.vatRate
        );
        totalUnitPrice += totalVariantPrice;
      }

      if (item.type === "variant-weight") {
        // for displaying the price incl. VAT in the basket and its total
        totalUnitPrice = calculateUnitPriceByUnitSystem({
          unitPrice: product.unitPrice,
          weight: item.options[0].choices[0].weight,
          unitSystem: product.unitSystem,
        });
      }

      let totalPrice = totalUnitPrice * item.count;
      let discountUnitPrice;
      let discountPercentage;

      if (!orderDiscount.amount && item.discountUnitPrice) {
        discountUnitPrice = item.discountUnitPrice
          ? item.discountUnitPrice
          : undefined;
        totalPrice -= item.discountUnitPrice;
        discountPercentage =
          (item.discountUnitPrice / (totalUnitPrice * item.count)) * 100;
      }

      const baseProduct = {
        id: product.id,
        productLineId: product.productLineId,
        name: product.name,
        unitPrice: product.unitPrice,
        category: product.category,
        quantity: item.count,
        totalDisplayPrice: priceFormatter({
          value: totalPrice,
          currency: meta.currency,
          locale: authData.locale,
        }),
        totalOriginalDisplayPrice: priceFormatter({
          value: totalUnitPrice * item.count,
          currency: meta.currency,
          locale: authData.locale,
        }),
        limit: product.limit,
        basketId: item.id,
        discountUnitPrice,
        discountPercentage,
        totalUnitPrice,
        totalPrice,
        vatRate: product.vatRate,
        exclVatUnitPrice: product.exclVatUnitPrice,
      };

      if (item.type === "regular") {
        const prod: PosProductInBasket = {
          ...baseProduct,
          type: item.type,
          unitSystem: "piece",
        };

        return prod;
      }

      if (item.type === "variant") {
        const prod: PosProductInBasket = {
          ...baseProduct,
          options: item.options,
          type: item.type,
          unitSystem: "piece",
        };

        return prod;
      }

      // weighted product flow
      const prod: PosProductInBasket = {
        ...baseProduct,
        // hack to calculate the weight in the product price
        unitPrice: calculateUnitPriceByUnitSystem({
          unitPrice: product.unitPrice,
          weight: item.options[0].choices[0].weight,
          unitSystem: product.unitSystem,
        }),
        // hack to calculate the weight in the product price
        exclVatUnitPrice: calculateUnitPriceByUnitSystem({
          unitPrice: product.exclVatUnitPrice,
          weight: item.options[0].choices[0].weight,
          unitSystem: product.unitSystem,
        }),
        options: item.options,
        type: item.type,
        unitSystem: item.unitSystem,
      };

      return prod;
    });

    const totalUnit = productsInBasket.reduce((acc, product) => {
      return acc + product.totalPrice;
    }, 0);

    let productsInBasketWithDiscount = productsInBasket;

    // if a discount has been set on the order, assign the correct discount to all products with positive price
    if (orderDiscount.amount) {
      const totalDiscount =
        orderDiscount.type === "percentage"
          ? totalUnit * (orderDiscount.amount / 100)
          : orderDiscount.amount;

      // calculate the discounts to be assigned
      const productDiscounts = productsInBasket
        .filter((p) => p.unitPrice > 0 && p.type !== "custom")
        .map((product) => {
          const proportion = product.totalPrice / totalUnit;
          const amount = round(totalDiscount * proportion, 0);

          return {
            basketId: product.basketId,
            amount,
          };
        });

      const totalGivenDiscount = productDiscounts.reduce(
        (acc, product) => acc + product.amount,
        0
      );

      // if the total discount is less than the order discount, add the difference to the first product
      const difference = totalDiscount - totalGivenDiscount;

      if (difference !== 0) {
        const firstProduct = productDiscounts[0];
        firstProduct.amount += difference;
      }

      productsInBasketWithDiscount = productsInBasket.map((product) => {
        const discount = productDiscounts.find(
          (p) => p.basketId === product.basketId
        );

        if (!discount) {
          return product;
        }

        const totalPrice = product.totalPrice - discount.amount;
        const totalDisplayPrice = priceFormatter({
          value: totalPrice,
          currency: meta.currency,
          locale: authData.locale,
        });

        const discountPercentage =
          orderDiscount.type === "percentage"
            ? orderDiscount.amount
            : (discount.amount * 100) / product.totalPrice;

        return {
          ...product,
          totalDisplayPrice,
          totalPrice,
          discountUnitPrice: discount.amount,
          discountPercentage,
        };
      });
    }

    const totalUnitDiscounted = productsInBasketWithDiscount.reduce(
      (acc, product) => {
        return acc + product.totalPrice;
      },
      0
    );

    return {
      meta,
      basket,
      products,
      productsInBasket: productsInBasketWithDiscount,
      isEmpty: !Object.keys(basket).length,
      totalUnit,
      totalUnitDiscounted,
      productsStatus,
      variants: data.variants,
    };
  }, [
    productsStatus,
    data,
    allowanceUsages,
    basket,
    orderDiscount,
    diningOption,
    authData.locale,
  ]);
}

export function useLatestUpdates() {
  const { groupName } = useEmployee();

  const { status: productStatus, dataUpdatedAt: productsUpdateAt } =
    useProducts({ groupName });

  const { status: profileStatus, dataUpdatedAt: profilesUpdatedAt } =
    useProfiles();

  const {
    data: currentTill,
    status: currentTillStatus,
    dataUpdatedAt: currentTillUpdatedAt,
  } = useCurrentTill();

  const { status: sessionStatus, dataUpdatedAt: sessionUpdatedAt } = useSession(
    currentTill?.sessionId
  );

  const last = Math.max(
    productsUpdateAt,
    profilesUpdatedAt,
    currentTillUpdatedAt,
    sessionUpdatedAt
  );

  return {
    last,
    queries: {
      products: { status: productStatus, last: productsUpdateAt },
      profiles: { status: profileStatus, last: profilesUpdatedAt },
      currentTill: { status: currentTillStatus, last: currentTillUpdatedAt },
      session: { status: sessionStatus, last: sessionUpdatedAt },
    },
  };
}

type SplitPaymentFlowType = {
  orderId: string | null;
  isActive: boolean;
  unitReceived: number;
  loading: boolean;
  reset: () => void;
};

/**
 * Hook to be used to find out information about the split payment,
 * e.g. whether it is active and how much cash has already been received
 */
export const useSplitPaymentFlow = (): SplitPaymentFlowType => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orderIdFromParams = searchParams.get("orderId");

  const { data: order, status: orderStatus } = useOrder(orderIdFromParams);

  const reset = () => {
    setSearchParams((params) => {
      params.delete("orderId");
      return params;
    });
  };

  if (!orderIdFromParams) {
    return {
      orderId: null,
      isActive: false,
      unitReceived: 0,
      loading: false,
      reset,
    };
  }

  if (orderStatus !== "success") {
    return {
      orderId: orderIdFromParams,
      isActive: false,
      unitReceived: 0,
      loading: true,
      reset,
    };
  }

  const unitReceived =
    order.payments?.reduce((acc, payment) => {
      return acc + payment.unitPrice;
    }, 0) ?? 0;

  return {
    orderId: orderIdFromParams,
    isActive: order.status === "pending" && unitReceived !== 0,
    unitReceived,
    loading: false,
    reset,
  };
};
