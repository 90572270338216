import { initClient } from "@ts-rest/core";
import { apiContract } from "@kanpla/api-contract";

import { KANPLA_API_URL } from "~/utils/constants";
import { getAuthToken } from "~/providers/store/auth";

const client = initClient(apiContract, {
  baseUrl: KANPLA_API_URL,
  baseHeaders: {},
});

function getAuthHeader() {
  const token = getAuthToken();

  if (!token) {
    throw new Error("No auth token");
  }

  return {
    authorization: `Bearer ${token}`,
    "ngrok-skip-browser-warning": "true",
  };
}

export { apiContract, client, getAuthHeader };
