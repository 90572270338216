import { faBarcodeRead, faQrcode } from "@fortawesome/pro-solid-svg-icons";
import { T } from "@repo/transifex";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { DialogStandBy } from "~/components/DialogStandBy";
import { useCodeScanner } from "~/hooks";
import { useAuthEmployeeQr, useCurrentTill } from "~/hooks/queries";
import {
  useAppDispatch,
  useSplitPaymentFlow,
  useBasketProducts,
  useCountDownState,
} from "~/providers/app";
import { basketActions } from "~/providers/store/basket";
import { useEmployee } from "~/providers/store/employee";

import { DialogCodeError } from "../DialogCodeError";
import { WelcomeGuest } from "../WelcomeGuest";

import { BasketFooter } from "./BasketFooter";
import { BasketHeader } from "./BasketHeader";
import { BasketItem } from "./BasketItem";
import { isPaymentInProgress } from "./isPaymentInProgress";

export const Basket = () => {
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const { groupName } = useEmployee();

  const { data: till } = useCurrentTill();
  const {
    productsInBasket,
    products,
    meta: { currency },
    isEmpty,
  } = useBasketProducts();

  const {
    mutate: submitQrCode,
    status: qrCodeStatus,
    reset: resetQrCode,
  } = useAuthEmployeeQr();

  const { isActive: isSplitPaymentActive } = useSplitPaymentFlow();

  const {
    mutate: scanBarcode,
    status: barcodeStatus,
    reset: resetBarcode,
  } = useMutation({
    mutationFn: async (barcode: string) => {
      const product = products.find((product) =>
        product.barcodeIds.includes(barcode)
      );

      if (
        pathname.includes("custom-product") ||
        pathname.includes("basket-product") ||
        pathname.includes("shopper-name") ||
        pathname.includes("discount-order") ||
        pathname.includes("invoice") ||
        pathname.includes("email")
      ) {
        return Promise.resolve();
      }

      if (product) {
        basketActions.addRegular(product.id);
        return Promise.resolve();
      }

      throw new Error("Barcode not registered");
    },
  });

  useCodeScanner({
    onScan: ({ code, isQrCode }) => {
      dispatch({ type: "COUNTDOWN_RESET" });

      // avoid submit the codes if the calls are still in pending
      // it avoid also to trigger the re-render of the hook
      if (barcodeStatus === "pending" || qrCodeStatus === "pending") return;

      if (isQrCode) {
        // if the basket is filled we don't let the user scan again the qr code
        if (!isEmpty) return;

        submitQrCode({ childId: code, currency });
      } else {
        scanBarcode(code);
      }
    },
  });

  const handleResetCountdown = () => {
    dispatch({ type: "COUNTDOWN_RESET" });
  };

  const { count } = useCountDownState();

  useEffect(() => {
    if (till?.type === "self-service") {
      handleResetCountdown();
      dispatch({ type: "COUNTDOWN_START" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on till change
  }, [till?.type]);

  return (
    <>
      {till?.type === "self-service" && isEmpty && groupName === null ? (
        <WelcomeGuest />
      ) : (
        <>
          <div className="flex size-full flex-1 flex-col justify-between bg-background-primary">
            <BasketHeader />

            <div className="flex flex-1 flex-col overflow-auto scrollbar-hide gradient-mask-b-90">
              {productsInBasket.map((basketItem) => (
                <BasketItem
                  basketProductId={basketItem.basketId}
                  disabled={isSplitPaymentActive}
                  discountPercentage={basketItem.discountPercentage}
                  key={basketItem.basketId}
                  options={basketItem.options}
                  productName={basketItem.name}
                  quantity={basketItem.quantity}
                  totalDisplayPrice={basketItem.totalDisplayPrice}
                  totalOriginalDisplayPrice={
                    basketItem.totalOriginalDisplayPrice
                  }
                  unitPrice={basketItem.unitPrice}
                />
              ))}
            </div>

            <BasketFooter />
          </div>

          {count <= 10 &&
            !isPaymentInProgress(pathname) &&
            till?.type === "self-service" &&
            till?.config.selectorSetup === "show-selectors" && (
              <DialogStandBy />
            )}
        </>
      )}

      {barcodeStatus === "error" ? (
        <DialogCodeError
          body={
            <T _str="Please try again or make sure that the product is created in Admin" />
          }
          icon={faBarcodeRead}
          onOutsideClick={() => {
            resetBarcode();
            dispatch({ type: "COUNTDOWN_RESET" });
          }}
          title={<T _str="Barcode not registered" />}
        />
      ) : null}

      {qrCodeStatus === "error" ? (
        <DialogCodeError
          body={<T _str="Please try again" />}
          icon={faQrcode}
          onOutsideClick={() => {
            resetQrCode();
            dispatch({ type: "COUNTDOWN_RESET" });
          }}
          title={<T _str="User not recognized" />}
        />
      ) : null}
    </>
  );
};
