import { useNavigate, useSearchParams } from "react-router-dom";
import { T } from "@repo/transifex";
import { priceFormatter } from "@repo/system";

import {
  useAbortTransaction,
  useCurrentTill,
  useOrder,
  useTransactionStatus,
} from "~/hooks/queries";
import { useAuth } from "~/providers/store/auth";
import { Button, Dialog, DialogLoading, DialogPayment } from "~/components";
import { useAppDispatch, useSplitPaymentFlow } from "~/providers/app";

import { TerminalReadyIcon } from "./TerminalReadyIcon";

const PosCardPage = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const serviceId = searchParams.get("serviceId");
  const shouldPoll = searchParams.get("poll");

  const dispatch = useAppDispatch();

  const { data: order, status } = useOrder(orderId);

  const auth = useAuth();
  const { data: till } = useCurrentTill();
  const terminalId = till?.terminalId ?? undefined;

  const { mutate: abortTransaction, status: abortTransactionStatus } =
    useAbortTransaction();

  useTransactionStatus(
    { serviceId, orderId, terminalId },
    shouldPoll === "true"
  );

  const { isActive: isSplitPaymentActive, unitReceived } =
    useSplitPaymentFlow();

  if (status === "pending")
    return <DialogLoading title={<T _str="Preparing payment" />} />;

  if (status === "error" || order.status === "failed") {
    return <DialogPayment status="error" />;
  }

  if (order.status === "cancelled") {
    return <DialogPayment status="cancelled" />;
  }

  if (order.status === "paid") {
    return <DialogPayment orderId={order.id} status="success" />;
  }

  return (
    <Dialog className="max-w-sm" size="md">
      <div className="flex flex-col items-center gap-y-5 p-5">
        <p className="text-base">
          <T _str="Amount" />
        </p>

        <p className="text-2xl font-bold">
          {priceFormatter({
            value:
              order.totalPriceUnit -
              (order.paidWithSaldoUnit ?? 0) -
              unitReceived,
            currency: order.currency,
            locale: auth.locale,
          })}
        </p>

        <div className="flex w-full flex-col items-center gap-5 rounded-2xl bg-background-secondary p-5">
          <p className="text-lg font-bold text-primary-light">
            <T _str="Terminal ready" />
          </p>

          <TerminalReadyIcon />
        </div>

        <Button
          disabled={!orderId || !terminalId}
          loading={abortTransactionStatus === "pending"}
          onClick={() => {
            if (!orderId || !terminalId) {
              throw new Error(
                "Cannot abort transaction: missing orderId and terminalId"
              );
            }
            abortTransaction(
              { orderId, serviceId, terminalId },
              {
                onSuccess: () => {
                  navigate("/pos");
                },
              }
            );

            if (isSplitPaymentActive) {
              dispatch({ type: "CASH_DRAWER_OPEN_REQUEST" });
            }
          }}
          variant="danger"
        >
          <T _str="Cancel" />
        </Button>
      </div>
    </Dialog>
  );
};

export { PosCardPage };
