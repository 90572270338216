import { round } from "@repo/system";
import { T, useT } from "@repo/transifex";
import type { StepVariant, StepVariantWeight } from "@repo/types";
import { useLocation, useNavigate } from "react-router-dom";

import { SwipeToDelete } from "~/components/SwipeToDelete";
import { useCurrentTill } from "~/hooks/queries";
import { basketActions, useOrderDiscount } from "~/providers/store/basket";

type BasketItemProps = {
  basketProductId: string;
  productName: string;
  quantity: number;
  options?: (StepVariant | StepVariantWeight)[];
  totalDisplayPrice: string;
  totalOriginalDisplayPrice: string;
  discountPercentage?: number;
  disabled: boolean;
  unitPrice: number;
};

export const BasketItem = ({
  basketProductId,
  productName,
  quantity,
  options,
  totalDisplayPrice,
  totalOriginalDisplayPrice,
  discountPercentage,
  disabled,
  unitPrice,
}: BasketItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const t = useT();

  const { data: till, status } = useCurrentTill();

  const orderDiscount = useOrderDiscount();

  const hasOrderDiscount = orderDiscount.amount > 0;
  const hasDiscount = typeof discountPercentage === "number" && unitPrice > 0;
  const hasVariants =
    typeof options !== "undefined" && options[0].name !== "scale_weight";

  const weightChoice =
    typeof options !== "undefined" &&
    "weight" in options[0].choices[0] &&
    options[0].choices[0];

  const gramsLabel = t("grams");
  const weightLabels = {
    "1-gram": gramsLabel,
    "100-grams": gramsLabel,
    kilogram: gramsLabel,
    piece: "",
  };

  if (status !== "success") return null;

  return (
    <SwipeToDelete
      onDelete={() => {
        basketActions.remove(basketProductId);
      }}
    >
      <button
        className="flex w-full items-center justify-between gap-5 border-b border-divider-main bg-background-primary p-4"
        disabled={disabled}
        onClick={() => {
          navigate(`${pathname}/basket-product/${basketProductId}`);
        }}
        type="button"
      >
        <div className="flex items-center gap-5">
          <span className="text-lg font-semibold text-text-primary">
            {quantity}
          </span>

          <div className="flex flex-col gap-0.5 text-left">
            <span className="line-clamp-2 text-lg text-text-primary">
              {productName}
            </span>

            {hasVariants ? (
              <span className="text-sm text-text-secondary">
                {options
                  .flatMap((o) =>
                    o.choices.flatMap((c) => {
                      if (c.amount === 0) return [];
                      return `${c.amount}x ${c.name}`;
                    })
                  )
                  .join(", ")}
              </span>
            ) : null}

            {weightChoice !== false && weightChoice.name ? (
              <span className="text-sm text-text-secondary">
                {`${weightChoice.weight} ${weightLabels[weightChoice.name]}`}
              </span>
            ) : null}

            {hasDiscount &&
              !hasVariants &&
              !weightChoice &&
              !hasOrderDiscount ? (
              <span className="text-sm text-text-secondary">
                <T
                  _str="{percentageDiscount}% discount"
                  percentageDiscount={round(discountPercentage, 2)}
                />
              </span>
            ) : null}
          </div>
        </div>

        {!till.moduleConfig.hidePrices ? (
          <div className="flex items-center gap-3">
            {hasDiscount && !hasOrderDiscount ? (
              <span className="text-text-secondary line-through	">
                {totalOriginalDisplayPrice}
              </span>
            ) : null}

            <span className="font-semibold text-text-secondary">
              {!hasOrderDiscount
                ? totalDisplayPrice
                : totalOriginalDisplayPrice}
            </span>
          </div>
        ) : null}
      </button>
    </SwipeToDelete>
  );
};
