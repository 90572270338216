import { priceFormatter } from "@repo/system";
import { T } from "@repo/transifex";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Dialog, DialogPayment } from "~/components";
import {
  useAbortTransaction,
  useCurrentTill,
  useOrder,
  useTransactionStatus,
} from "~/hooks/queries";
import {
  useAppDispatch,
  useBasketProducts,
  useSplitPaymentFlow,
} from "~/providers/app";
import { useAuth } from "~/providers/store/auth";
import { useEmployee } from "~/providers/store/employee";
import { TerminalReadyIcon } from "~/routes/__app.pos.$categoryId.card/TerminalReadyIcon";
import { calculateAmountForSaldo } from "~/utils/basket";

const PosCardV3Page = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const serviceId = searchParams.get("serviceId");
  const shouldPoll = searchParams.get("poll");

  const dispatch = useAppDispatch();

  const { locale } = useAuth();

  const { data: order, status } = useOrder(orderId);

  const { data: till } = useCurrentTill();
  const terminalId = till?.terminalId ?? undefined;

  const { mutate: abortTransaction, status: abortTransactionStatus } =
    useAbortTransaction();

  useTransactionStatus(
    { serviceId, orderId, terminalId },
    shouldPoll === "true"
  );

  const { isActive: isSplitPaymentActive, unitReceived } =
    useSplitPaymentFlow();
  const { totalUnitDiscounted, meta } = useBasketProducts();
  const { saldo: unitSaldo } = useEmployee();

  if (status === "error" || order?.status === "failed") {
    return <DialogPayment status="error" />;
  }

  if (order?.status === "cancelled") {
    return <DialogPayment status="cancelled" />;
  }

  if (order?.status === "paid") {
    return <DialogPayment orderId={order.id} status="success" />;
  }

  const totalPrice = calculateAmountForSaldo(totalUnitDiscounted, unitSaldo);
  const totalPriceFormatted = priceFormatter({
    value: totalPrice.payWithOther - unitReceived,
    currency: meta.currency,
    locale,
  });

  return (
    <Dialog className="max-w-sm" size="md">
      <div className="flex flex-col items-center gap-y-5 p-5">
        <div className="flex w-full flex-col items-center gap-5 rounded-2xl bg-background-secondary p-5">
          <p className="text-lg font-bold text-primary-light">
            <T _str="Awaiting payment on terminal" />
          </p>
          <p className="text-2xl font-bold">{totalPriceFormatted}</p>

          <TerminalReadyIcon />
        </div>

        <Button
          disabled={!terminalId}
          loading={abortTransactionStatus === "pending"}
          onClick={() => {
            if (!terminalId) {
              throw new Error("Cannot abort transaction: missing terminalId");
            }
            abortTransaction(
              {
                ...(orderId && { orderId }),
                serviceId,
                terminalId,
              },
              {
                onSuccess: () => {
                  navigate("/pos");
                },
              }
            );

            if (isSplitPaymentActive) {
              dispatch({ type: "CASH_DRAWER_OPEN_REQUEST" });
            }
          }}
          variant="danger"
        >
          <T _str="Cancel" />
        </Button>
      </div>
    </Dialog>
  );
};

export { PosCardV3Page };
