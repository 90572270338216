import { T } from "@repo/transifex";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { clsx } from "clsx";

import { Button, DialogError, UnattendedLogoutButton } from "~/components";
import { GridLayout } from "~/components/GridLayout";
import { ItemSkeleton } from "~/components/ItemSkeleton";
import { useCurrentTill } from "~/hooks/queries";
import { employeeActions } from "~/providers/store/employee";
import { useDiningOption } from "~/providers/store/basket";

import { DiningOptionItem } from "./DiningOptionItem";

const DiningOptionsPage = () => {
  const diningOption = useDiningOption();

  const { data: till, status } = useCurrentTill();

  const textInputField = till?.preCheckoutTextInputs?.[0];

  if (!textInputField?.options || textInputField.options.length === 0) {
    return (
      <DialogError
        childrenPosition="top"
        error="No dining options found"
        size="md"
      >
        <Link to="/pos">
          <Button
            onClick={() => {
              employeeActions.reset();
            }}
            variant="danger"
          >
            <T _str="Back" />
          </Button>
        </Link>
      </DialogError>
    );
  }

  if (status === "pending") {
    return (
      <div className="flex h-full flex-col items-center">
        <h1 className="animate-pulse text-3xl text-text-disabled">
          <T _str="Loading dining options..." />
        </h1>

        <GridLayout className="!w-4/5 !grid-cols-2">
          {Array.from({ length: 2 }).map(() => (
            <ItemSkeleton key={uuidv4()} />
          ))}
        </GridLayout>
      </div>
    );
  }

  return (
    <div className="p-12">
      {till?.type === "self-service" && !diningOption ? (
        <UnattendedLogoutButton />
      ) : (
        <Link
          to={till?.type === "self-service" || diningOption ? "/pos" : "/menu"}
        >
          <Button
            onClick={() => {
              if (!diningOption) {
                employeeActions.reset();
              }
            }}
            variant="secondary"
          >
            <T _str="Back" />
          </Button>
        </Link>
      )}

      <div className="flex flex-col items-center pt-24">
        <h1 className="text-6xl">{textInputField.title}</h1>
        <h2 className="text-xl">{textInputField.subtitle}</h2>

        <GridLayout
          className={clsx("gap-4 pt-20", {
            "!w-3/5 !grid-cols-2": textInputField.options.length <= 4,
          })}
        >
          {textInputField.options.map((option) => (
            <DiningOptionItem
              inputId={textInputField.key}
              key={option}
              value={option}
            />
          ))}
        </GridLayout>
      </div>
    </div>
  );
};

export { DiningOptionsPage };
